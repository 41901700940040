import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiClientConstant, ApiConnector, Table } from 'api-client';
import { ConnectionService } from '../../../../services/connection-service';
import { AppConfig } from '../../../app.config';
import { Broadcaster } from '../../../../components/broadcaster';
import { InputType } from '../../../../../typings/client/input';

@Component({
  selector: 'address-book-edit-modal',
  templateUrl: './address-book-edit.html',
})
export class AddressBookEditModal {
  addressBook: any;
  user: any;
  tagsOptions: Array<InputType.SelectOption>;
  stateOptions: Array<InputType.SelectOption>;
  loading: boolean = true;

  constructor(private conn: ConnectionService,
    private router: Router,
    private appConfig: AppConfig,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private broadcaster: Broadcaster,
    private dialogRef: MatDialogRef<AddressBookEditModal>) {
  }

  async ngOnInit(): Promise<void> {
    this.user = await ApiConnector.findOne(Table.User,
      { where: { objectId: this.data.user.id } });
    const languageStringTag = await ApiConnector.findOne(Table.LanguageStringTag,
      { where: { name: 'address_tag' } });
    const languageString = await ApiConnector.find(Table.LanguageString,
      { where: { tags: [languageStringTag] } });
    this.tagsOptions = languageString.map((each: any): InputType.SelectOption => ({ value: each.get(this.user.get('languagePreference')),
      display: each.get('en') }));
    this.stateOptions = this.appConfig.Shared.Order.States.map((each: any): InputType.SelectOption => ({ value: each.value,
      display: each.name }));
    if (this.data.action === this.appConfig.Shared.Actions.edit) {
      this.addressBook = this.data.currentAddress;
      await this.addressBook.fetch();
    } else {
      this.addressBook = new Table.AddressBook();
      this.addressBook.set('user', this.user);
      this.addressBook.set('mobileNumber', this.user.get('MobileNumber'));
      this.addressBook.set('contactName', this.user.get('PatientName'));
    }
    this.loading = false;
  }

  private constructDeliveryAddress(): void {
    const address = [];
    address.push(this.addressBook.get('buildingDetails'));
    address.push(this.addressBook.get('city'));
    address.push(this.addressBook.get('state'));
    address.push(this.addressBook.get('zipCode'));
    const deliveryAddress = address.filter((each: string): boolean => !!each).join(', ');
    this.addressBook.set('deliveryAddress', deliveryAddress);
  }

  async saveAddressBook(): Promise<void> {
    if (this.addressBook.dirtyKeys().includes('zipCode')) {
      if (this.addressBook.get('zipCode')) {
        const code = this.addressBook.get('zipCode').toString();
        if (!code.match('^[1-9][0-9]{5}$')) {
          alert('Please enter a valid Zipcode');
          return;
        }
      }
      this.addressBook.set('zipCode', Number(this.addressBook.get('zipCode')));
    }
    if (this.addressBook.dirtyKeys().includes('contactName')) {
      if (this.addressBook.get('contactName')) {
        const contactName = this.addressBook.get('contactName');
        const result = this.nameCheckValidator(contactName);
        if (result !== null) {
          alert(result);
          return;
        }
      }
      this.addressBook.set('contactName', Number(this.addressBook.get('contactName')));
    }
    if (this.addressBook.dirtyKeys().includes('alternateNumber')) {
      if (this.addressBook.get('alternateNumber')) {
        const altNumber = this.addressBook.get('alternateNumber').toString();
        if (altNumber.length !== 10) {
          alert('Please enter a valid alternate mobile number');
          return;
        }
      }
      this.addressBook.set('alternateNumber', Number(this.addressBook.get('alternateNumber')));
    }
    if (this.addressBook.dirtyKeys().includes('mobileNumber')) {
      if (this.addressBook.get('mobileNumber')) {
        const mobileNumber = this.addressBook.get('mobileNumber').toString();
        if (mobileNumber.length !== 10) {
          alert('Please enter a valid mobile number');
          return;
        }
      }
      this.addressBook.set('mobileNumber', this.addressBook.get('mobileNumber'));
    }
    if (this.addressBook.dirtyKeys().includes('state')) {
      if (this.addressBook.get('state') && this.addressBook.get('zipCode')) {
        const stateInfo = await this.conn.getPinCodeInfo(this.addressBook.get('zipCode'), {});
        const state = this.addressBook.get('state');
        if (stateInfo?.get('state') !== state) {
          alert(`Selected state - ${state} is wrong. For the current pincode - ${stateInfo?.get('state')} should be the one`);
          return;
        }
      }
      this.addressBook.set('state', this.addressBook.get('state'));
    }
    this.constructDeliveryAddress();
    try {
      await this.addressBook.save();
      this.broadcaster.broadcast('NOTIFY', { message: 'Address Book Saved Successfully',
        type: this.appConfig.Shared.Toast.Type.SUCCESS });
      this.dialogRef.close(this.addressBook);
    } catch (error) {
      this.broadcaster.broadcast('NOTIFY', { message: error.message, type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }

  private nameCheckValidator(value: string): string {
    if (/[0-9$&+,:;=?@#|'<>^*()%!\-_%{}]/.test(value)) {
      return 'Number or Special Character are not allowed as Name';
    }
    if (value?.split(' ')?.length > 0) {
      if (value.split(' ')[0] === '') {
        return 'Empty names are not allowed';
      }
    }
    if (value?.split(' ')?.length > 3) {
      return 'Only three words are allowed in Name';
    }
    return null;
  }

  hideModal(): void {
    this.dialogRef.close();
  }
}
