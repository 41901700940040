import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { ApiClientConstant, Table, RequestQueryPayload, ApiConnector } from 'api-client';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FaviconService } from 'src/services/favicon-service';
import { TabNamingService } from 'src/services/tab-naming-service';
import { ValueOf } from 'api-client/src/common';
import { ConnectionService } from '../../services/connection-service';
import { TimeService } from '../../services/time-service';
import { LocalStorage } from '../../services/local-storage-service';
import { AppConfig } from '../app.config';
import { WindowRefService } from '../../services/window-ref-service';
import { Broadcaster } from '../../components/broadcaster';
import { HelperService } from '../../services/helper-service';
import { EditInstantCheckup } from '../chat/view/instant-checkup/edit-instant-checkup';
import { FollowUpDialog } from '../chat/view/follow-up';
import { SupportTicketHistoryComponent } from './support-ticket-history/support-ticket-history.component';
import { InputType } from '../../../typings/client/input';
import { VoiceRecordingComponent } from '../../components/voice-recording/voice-recording.component';
import { RegimenProductsEditComponent } from '../../components/regimen-products-edit/regimen-products-edit.component';
import { FollowUpReportCreationComponent } from '../../components/followUp-report-creation/followUp-report-creation.component';
import { DataUploadModal } from '../../components/modal/dataUploadModal';
import { ConsentFormComponent } from '../../components/consent-form/consent-form.component';
import { ContinueTabPopupModal } from './continue-tab-popup/continue-tab-popup.component';

interface ReportObject {
  imageConfigs: Array<{ beforeInstantId: string, afterInstantId: string, imageConfig: any }>;
  treatmentProgress: Array<{
    issue: string,
    issueLanguageString: any,
    score: number,
  }>;
  investigations: Array<string>;
  treatmentOutcome: { selectedTreatmentOutcome: string };
}
@Component({
  selector: 'patient-history',
  templateUrl: './patient.html',
  styleUrls: ['./patient.scss'],
})

export class PatientComponent implements OnInit {
  user: any;
  userData: any = [];
  doctor: any;
  editNoteObject: any;
  isInternalUser: boolean;
  nextFollowUpDate: any;
  regimens: Array<any> = [];
  orders: Array<any> = [];
  instantCheckupsCompareIds: Array<any> = [];
  selectedInstantCheckup: Array<any> = [];
  decodeURIComponent: any;
  lastDeliveredRegimen: any;
  extraData: any = {
    married: '-',
    pregnancy: '',
    femaleHormonal: '-',
    sensitiveSkin: '-',
    npdExperimentEnabled: false,
    regimenChangeNeeded: false,
    discontinuedProducts: [],
  };
  tag: string;
  tags: Array<string> = [];
  femaleHormonal: string = '';
  followUp: any;
  attendToChatCacheObj: any;
  currentUser: any;
  userRoles: Array<any> = [];
  moment: any;
  enableDelete: boolean = true;
  followUps: Array<any> = [];
  selectedFollowupId: string;
  webAppUrl: SafeResourceUrl;
  showNotes: false;
  orderMedicalRecord: any = {};
  sopFieldsToShow: Array<string> = [];
  labelType: Array<any> = [];
  thyroidOrSeriousIllness: { value?: string, updatedAt?: Date } = {};
  sensitiveSkin: string;
  thyroidOrSeriousIllnessOptions: Array<string> = ['Low iron', 'thyroid', 'Deficiency of any vitamin',
    'Significant weight loss in the last 6 months', 'Feeling excessively tired or stressed', 'None of the above', 'I don\'t know.'];
  selectedOrderType: string;
  orderType: Array<string> = ['ALL', 'DELIVERED', 'SHIPPED', 'OUT_FOR_DELIVERY', 'INITIAL', 'CANCELED', 'RETURNED',
    'ONLINE_PAYMENT_PENDING'];
  selectedChatType: 'userChat' | 'chatSummary' = 'chatSummary';
  showNewUI: boolean = true;
  completedFollowUpOptions: Array<InputType.SelectOption>;
  selectedFollowupClass: ValueOf<typeof ApiClientConstant.FollowUpReport.Type>;
  selectedRegimen: any;
  pregnentMessages: Array<string> = ['I am pregnant'];
  breastfeedingMessages: Array<string> = ['I am a new mom and breastfeeding', 'Yes, I am breastfeeding'];
  defaultSelectedFollowUp: string;
  reportObject: Partial<ReportObject>;
  pendingReportTypes: Array<ValueOf<typeof ApiClientConstant.FollowUpReport.Type>> = [];
  disableFinishAndSendButton: boolean = true;
  audioUrl: string;
  isFollowUpUnderFollowupSop: boolean = false;
  isFinasterideConsent: boolean = false;
  isIsotretConsent: boolean = false;
  tabProducts: Array<{id: string, noOfDays?: string, title: string}> = [];
  TabletsId: Array<string> = ['UMyVJbm3Jq', 'ed9jLtJyqr'];
  showPopup: boolean = true;
  shown: boolean = false;
  dateOfLastDelivery: any;
  regimenDetails: Array<any> = [];
  show: boolean = false;
  questionForDoc: Array<string> = [];
  complementProductIds: Array<string> = [];
  disableVoiceNoteSOP: boolean = false;
  customVoiceNoteToggle: boolean = false;
  audioTemplateNameCopy: string = '';
  userCommunicationLanguage: string;
  changeNeededReason: string = '';
  quickActions: Array<{section: string, icon: string, name: string}> = [
    { section: 'chatSection', icon: 'assets/images/quickActionChat.png', name: 'Chat' },
    { section: 'regimenDetailsSection', icon: 'assets/images/quickActionRegimenDetails.png', name: 'Regimen Details' },
    { section: 'checkupSection', icon: 'assets/images/quickActionCheckup.png', name: 'Checkup Section' },
    { section: 'regimenSection', icon: 'assets/images/quickActionRegimen.png', name: 'Regimen Selection' },
    { section: 'voiceRecordingSection', icon: 'assets/images/quickActionRecording.png', name: 'Voice Note' }];
  newMedicalNoteAdded: boolean;
  @ViewChild('newHeader', { static: false }) newHeader: ElementRef;
  @ViewChild('VoiceRecordingComponent') VoiceRecordingComponent: VoiceRecordingComponent;
  @ViewChild('RegimenProductsEditComponent', { static: false }) RegimenProductsEditComponent: RegimenProductsEditComponent;
  @ViewChild('FollowUpReportCreationComponent', { static: false }) FollowUpReportCreationComponent: FollowUpReportCreationComponent;
  @ViewChild('consentForm') consentForm: ConsentFormComponent;
  @ViewChild('scrollTarget', { static: false }) scrollTarget: ElementRef;
  @ViewChild('content', { static: false }) content: ElementRef;
  constructor(private conn: ConnectionService,
    private router: Router,
    private route: ActivatedRoute,
    private appConfig: AppConfig,
    private localStorageService: LocalStorage,
    private timeService: TimeService,
    private dialog: MatDialog,
    private window: WindowRefService,
    private helper: HelperService,
    private dom: DomSanitizer,
    private faviconService: FaviconService,
    private tabNameService: TabNamingService,
    private broadcaster: Broadcaster) {
    this.moment = moment;
  }

  async ngOnInit(): Promise<any> {
    const smallScreenExperiment = await this.conn.findExperiment({
      where: { key: 'disableSmallScreenExperiment', enable: true },
      project: ['key'],
    });
    if (this.window.nativeWindow.screen.width < 1450 && smallScreenExperiment) {
      this.broadcaster.broadcast('NOTIFY', { message: 'This Page is not supported in current screen',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    this.tags = ['star', 'bad', 'noStar'];
    this.decodeURIComponent = decodeURIComponent;
    this.currentUser = this.conn.getCurrentUser();
    this.userRoles = this.localStorageService.getJsonValue('userRoles');
    this.labelType = [
      { display: 'MBBS', value: 'MBBS' },
      { display: 'DERMATOLOGIST', value: 'DERMATOLOGIST' },
    ];
    await this.conn.getUserByUserName(this.route.snapshot.queryParams.username)
      .then((user: any) => {
        this.broadcaster.broadcast('ChatUserUpdate', { user });
        this.user = user;
        this.userCommunicationLanguage = this.user.get('communicationLanguagePreference') || this.user.get('languagePreference');
        this.faviconService.setFavicon(this.user.get('PatientName'));
        this.tabNameService.setTitle('', this.user.get('PatientName'), 'Timeline');
        this.findUserMarriedStatus(user);
        this.findUserPregnancyStatus(user);
        this.findFemaleHormonalFromActiveConsultationSession(this.user);
        this.fetchQuestionAndUpdateThyroidOptions();
      });
    await this.loadFollowUp();
    this.attendToChatCacheObj = await this.conn.findAttendToChatCache(this.user);
    await this.conn.fetchFollowUpHistoryData(this.route.snapshot.queryParams.username)
      .then((data: any) => {
        const followUpTimeline = [];
        let instantCheckups = [];
        let progressReport = [];
        let deletedRegimen = [];
        let regimenChanges = [];
        let medicalNotes = [];
        let operatorNotes = [];
        data.forEach((each: any, index: number) => {
          if (each.followup) {
            followUpTimeline.push([
              { deletedRegimen,
                actionLog: regimenChanges,
                medicalNote: medicalNotes,
                operatorNotes,
                instantCheckups,
                progressReport }]);
            instantCheckups = [];
            regimenChanges = [];
            medicalNotes = [];
            progressReport = [];
            operatorNotes = [];
            deletedRegimen = [];
            followUpTimeline.push([{ followup: [each] }]);
          } else if (each.regimen) {
            each.regimen.set('productOrderMapping', {});
            this.regimens.push(each.regimen);
          } else if (each.order) {
            this.orders.push(each.order);
          } else {
            if (each.instantCheckup) instantCheckups.push(each);
            else if (each.progressReport) progressReport.push(each);
            if (each.deletedRegimen) deletedRegimen.push(each);
            if (each.actionLog) regimenChanges.push(each);
            if (each.medicalNote) medicalNotes.push(each);
            if (each.operatorNotes) operatorNotes.push(each);
          }
          if (index === data.length - 1) {
            followUpTimeline.push([
              { deletedRegimen,
                actionLog: regimenChanges,
                medicalNote: medicalNotes,
                operatorNotes,
                instantCheckups,
                progressReport }]);
          }
        });
        this.userData = followUpTimeline;
        this.regimens.forEach((each: any) => {
          const productOrderMapping = each.get('productOrderMapping');
          this.orders.forEach((eachO: any) => {
            (eachO.get('products') || []).forEach((eachP: any) => {
              productOrderMapping[eachP.id] = eachO.get('deliveredOn');
            });
          });
        });
      });
    this.shortRegimensArray();
    await Promise.all([
      this.findFollowUpChatURL(),
      this.checkRegimenSopQuestions(),
      this.checkUserIsDissatisfied(),
    ]);
    await this.updateDefaultDataForNewUI();
    await this.checkDoctorCanCompleteFollowup();
    this.questionForDoc.push(this.followUp.get('anyOtherConcernInFollowup'));
    this.questionForDoc.push(this.followUp.get('ProgressCheckFaceAppointment'));
  }

  getRegimenDetails(data: any): void {
    data.forEach((product: any) => {
      if (product.activeProduct) {
        this.regimenDetails.push(product);
      }
    });
    this.calculateNoOfDaysOnRegimen();
  }

  shortRegimensArray(): void {
    const regimenOrder = ['FACE', 'HAIR', 'BODY'];
    const tempRegimenArray = [];
    regimenOrder.forEach((regimenClass: string) => {
      const regimen = this.regimens.find((each: any) => each.get('class') === regimenClass);
      if (regimen) tempRegimenArray.push(regimen);
    });
    this.regimens = tempRegimenArray;
  }

  calculateScrollPosition(): void {
    if (this.scrollTarget) {
      const elementPosition = this.scrollTarget.nativeElement.offsetTop;
      if (this.content.nativeElement.scrollTop >= elementPosition
        && this.show
        && !this.shown) {
        const dialogref = this.dialog.open(ContinueTabPopupModal, { data: { products: this.tabProducts } });
        this.shown = true;
      }
    }
  }

  onScroll(): void {
    this.calculateScrollPosition();
  }

  calculateNoOfDaysOnRegimen(): void {
    this.regimenDetails.forEach((product: any) => {
      if (this.TabletsId.includes(product.id) && product.activeProduct) {
        if (!product.orders?.length) {
          this.tabProducts.push({ id: product.id, noOfDays: '0 Days', title: product.title });
          this.show = true;
        } else if (+product.differenceBtwStartAndEnd.split(' ')[0] > 7) {
          this.tabProducts.push({ id: product.id, noOfDays: product.differenceBtwStartAndEnd, title: product.title });
          this.show = true;
        }
      }
    });
  }

  async checkUserIsDissatisfied(): Promise<void> {
    if (![this.followUp.get('followUpFaceImprovement'), this.followUp.get('followUpHairImprovement'),
      this.followUp.get('followUpBodyImprovement')].includes('😕 Don\'t see much change')) {
      return;
    }
    const activeRegimens = await this.conn.findRegimens({
      where: { forUser: this.user, active: true },
      ascending: 'activeFromDate',
      project: ['activeFromDate', 'class'],
    });
    if (this.followUp.get('followUpFaceImprovement') === '😕 Don\'t see much change') {
      const [activeFaceRegimen]: any = activeRegimens
        .filter((regimen: any) => regimen.get('class') === ApiClientConstant.Regimen.Class.FACE);
      if (moment().subtract(10, 'weeks').isAfter(activeFaceRegimen?.get('activeFromDate'))) {
        this.extraData.dissatisfiedUser = true;
      }
    } else {
      const [activeHairRegimen]: any = activeRegimens
        .filter((regimen: any) => regimen.get('class') === ApiClientConstant.Regimen.Class.HAIR);
      if (moment().subtract(10, 'weeks').isAfter(activeHairRegimen.get('activeFromDate'))) {
        this.extraData.dissatisfiedUser = true;
      }
    }
  }
  async fetchQuestionAndUpdateThyroidOptions(): Promise<void> {
    const question = await this.conn.findQuestions({ where: { uniqueIdentifier: 'ThyroidOrSeriousIllness' } });
    const title = question[0]?.get('title');
    const regex = /^\d+\.\s(.+)$/gm;
    const options = [];
    let match;
    // eslint-disable-next-line no-cond-assign
    while ((match = regex.exec(title)) !== null) {
      options.push(match[1]);
    }
    this.thyroidOrSeriousIllnessOptions = options;
  }

  async saveFollowup(): Promise<void> {
    if (!this.followUp.dirtyKeys().length) {
      this.broadcaster.broadcast('NOTIFY', { message: 'No Changes Found', type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    try {
      await this.followUp.save();
      this.broadcaster.broadcast('NOTIFY', { message: 'saved Successfully', type: this.appConfig.Shared.Toast.Type.SUCCESS });
    } catch (error: any) {
      this.broadcaster.broadcast('NOTIFY', { message: error.message, type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }

  async captureFollowup(): Promise<void> {
    try {
      this.followUp.set('allocatedDoctor', this.currentUser);
      this.followUp.set('doctorConfirmed', true);
      await this.followUp.save();
      this.broadcaster.broadcast('NOTIFY', { message: 'saved Successfully', type: this.appConfig.Shared.Toast.Type.SUCCESS });
    } catch (error: any) {
      this.broadcaster.broadcast('NOTIFY', { message: error.message, type: this.appConfig.Shared.Toast.Type.ERROR });
    }
  }

  async checkRegimenSopQuestions(): Promise<void> {
    const orders = await this.conn.findOrders({ where: { user: this.user, type: ApiClientConstant.Order.Type.REGIMEN } });
    if (!orders?.length) return;
    this.orderMedicalRecord = JSON.parse(JSON.stringify(await ApiConnector.find(Table.OrderMedicalRecord, {
      where: { order: orders },
      limit: 1,
      descending: 'createdAt',
    })));
    this.orderMedicalRecord.forEach((each_: any) => {
      const each = each_;
      delete each.ACL;
      delete each.createdAt;
      delete each.updatedAt;
      delete each.doctor;
      delete each.order;
      delete each.objectId;
    });
    let allElements = [];
    this.orderMedicalRecord.forEach((each: any) => {
      allElements = [...Object.keys(each)];
    });

    this.sopFieldsToShow = Array.from(new Set(allElements));
  }

  async findUserMarriedStatus(user: any): Promise<any> {
    const femaleMarriedRow = await this.conn.findLatestResponseForFields(user, ['FemaleMarried', 'followUpMarried']);
    if (femaleMarriedRow) {
      this.extraData.married = femaleMarriedRow;
    }
  }

  async findFemaleHormonalFromActiveConsultationSession(user: any): Promise<void> {
    const where = { user, archive: false };
    const consultationSessions = await this.conn.findConsultationSession({
      where,
      project: ['FemaleHormonal', 'ThyroidOrSeriousIllness', 'SensitiveSkin'],
      descending: 'createdAt',
    });
    if (!consultationSessions.length) {
      return;
    }
    const femaleHormonalUniqueAnswers = new Set();
    consultationSessions.forEach((session: any) => {
      femaleHormonalUniqueAnswers.add(session.get('FemaleHormonal'));
      if (!this.thyroidOrSeriousIllness.value) {
        const thyroidOrSeriousIllnessValue = this.thyroidOrSeriousIllnessOptions[
          this.convertToNumberIfValid(session.get('ThyroidOrSeriousIllness')) - 1];
        this.thyroidOrSeriousIllness.value = thyroidOrSeriousIllnessValue;
        this.thyroidOrSeriousIllness.updatedAt = session.createdAt;
      }
    });
    this.sensitiveSkin = consultationSessions[0].get('SensitiveSkin');
    this.femaleHormonal = Array.from(femaleHormonalUniqueAnswers).toString();
  }
  public convertToNumberIfValid(input: string): any {
    if (/^[0-9]+(\.[0-9]*)?$/.test(input)) {
      return parseFloat(input);
    }
    return NaN;
  }

  async findUserPregnancyStatus(user: any): Promise<any> {
    const pregnancyQuestionIds = [
      'FemalePregOrNewMom',
      'followUpPregaNewMom',
      'followUpBreastFeeding',
      'followUpPlanningPragnancy',
      'followUpPregnant',
    ];
    const response = await this.conn.findLatestResponseForFields(user, pregnancyQuestionIds);
    if (response) {
      this.extraData.pregnancy = response;
    }
  }

  shiftTag(instantCheckup: any): void {
    this.tag = this.tags.shift();
    this.tags.push(this.tag);
    instantCheckup.set('tag', this.tag);
    instantCheckup.save();
  }

  addImagesToComparison(event: any, instantCheckup: any): void {
    if (this.selectedInstantCheckup.length > 0) {
      if (this.helper.getInstantCheckupClassByType(this.selectedInstantCheckup[0].type)
        !== this.helper.getInstantCheckupClassByType(instantCheckup.instantCheckup.get('type'))) {
        this.broadcaster.broadcast('NOTIFY', { message: 'Choose Image Of Same Type', type: this.appConfig.Shared.Toast.Type.ERROR });
        return;
      }
    }
    const indexOfId = this.selectedInstantCheckup.findIndex((value: any) => value.id === instantCheckup.instantCheckup.id);
    if (indexOfId >= 0) {
      this.selectedInstantCheckup.splice(indexOfId, 1);
      instantCheckup.instantCheckup.set('tag', 'noStar');
      instantCheckup.instantCheckup.save();
    } else {
      this.selectedInstantCheckup.push({ id: instantCheckup.instantCheckup.id, type: instantCheckup.instantCheckup.get('type') });
      instantCheckup.instantCheckup.set('tag', 'comparison');
      instantCheckup.instantCheckup.save();
    }
    if (this.selectedInstantCheckup) {
      this.instantCheckupsCompareIds = [];
      this.selectedInstantCheckup.forEach((each: any) => {
        this.instantCheckupsCompareIds.push(each.id);
      });
    }
  }

  async openCompareImages(): Promise<void> {
    const url = this.conn.getBaseUrl();
    this.window.nativeWindow.open(
      `${url}/compare/${this.instantCheckupsCompareIds[0]}/${this.instantCheckupsCompareIds[1]}/${this.user.get('username')}`
      + `?followUpId=
      }${this.followUp?.id}&ids=${this.instantCheckupsCompareIds.slice(2)}`);
  }

  findRequiredImages(currentFollowUpImages: Array<any>): void {
    let firstImageIndex;
    const firstFrontFace = currentFollowUpImages.find((instantCheck: any, index: number): any => {
      if (instantCheck.instantCheckup.get('type') === (ApiClientConstant.InstantCheckup.Type.FRONT_FACE
        || ApiClientConstant.InstantCheckup.Type.FULL_FACE)) {
        firstImageIndex = index;
        return instantCheck.instantCheckup;
      }
      return '';
    });
    const secondFrontFace = currentFollowUpImages.find((instantCheck: any, index: number): any => {
      if (instantCheck.instantCheckup.get('type') === (ApiClientConstant.InstantCheckup.Type.FRONT_FACE
        || ApiClientConstant.InstantCheckup.Type.FULL_FACE) && index > firstImageIndex) {
        return instantCheck.instantCheckup;
      }
      return '';
    });
    const url = this.conn.getBaseUrl();
    this.window.nativeWindow.open(
      `${url}/compare/${firstFrontFace.instantCheckup.id}/${secondFrontFace.instantCheckup.id}/${this.user.get('username')}`);
  }

  async automaticCompare(type: string): Promise<void> {
    const types = [];
    switch (type) {
      case 'face': {
        types.push(ApiClientConstant.InstantCheckup.Type.FULL_FACE);
        types.push(ApiClientConstant.InstantCheckup.Type.SIDE_FACE);
        types.push(ApiClientConstant.InstantCheckup.Type.FRONT_FACE);
        types.push(ApiClientConstant.InstantCheckup.Type.LEFT_SIDE_FACE);
        types.push(ApiClientConstant.InstantCheckup.Type.RIGHT_SIDE_FACE);
        break;
      }
      case 'hair': {
        types.push(ApiClientConstant.InstantCheckup.Type.HAIR);
        types.push(ApiClientConstant.InstantCheckup.Type.HAIR_TOP);
        types.push(ApiClientConstant.InstantCheckup.Type.HAIR_FRONT);
        break;
      }
      case 'body': {
        types.push(ApiClientConstant.InstantCheckup.Type.BODY);
        break;
      }
      default:
    }
    const instantCheckups = JSON.parse(JSON.stringify(await ApiConnector.find(Table.InstantCheckup, {
      where: { user: this.user, type: { $in: types }, createdAt: { $gte: moment().subtract(120, 'days').toDate() } },
      descending: 'createdAt',
      project: ['type', 'createdAt'],
    })));
    const url = this.conn.getBaseUrl();
    switch (type) {
      case 'face': {
        const frontFaceIds = this.getTwoComparisonOfType([ApiClientConstant.InstantCheckup.Type.FULL_FACE,
          ApiClientConstant.InstantCheckup.Type.FRONT_FACE], instantCheckups);
        const leftSideFaceIds = this.getTwoComparisonOfType([ApiClientConstant.InstantCheckup.Type.LEFT_SIDE_FACE], instantCheckups);
        const rightSideFaceIds = this.getTwoComparisonOfType([ApiClientConstant.InstantCheckup.Type.RIGHT_SIDE_FACE], instantCheckups);
        const sideFaceIds = this.getTwoComparisonOfType([ApiClientConstant.InstantCheckup.Type.SIDE_FACE], instantCheckups);
        if (frontFaceIds.length !== 2) {
          this.broadcaster.broadcast('NOTIFY', { message: 'Can\'t find two front face',
            type: this.appConfig.Shared.Toast.Type.ERROR });
          return;
        }
        this.window.nativeWindow.open(`${url}/compare/${frontFaceIds[0]}/${frontFaceIds[1]}/${this.user.get('username')}`
          + `?ids=${[...leftSideFaceIds, ...rightSideFaceIds, ...sideFaceIds]}&followUpId=${this.followUp?.id}`);
        break;
      }
      case 'hair': {
        const hairTopIds = this.getTwoComparisonOfType([ApiClientConstant.InstantCheckup.Type.HAIR_TOP], instantCheckups);
        const hairFrontIds = this.getTwoComparisonOfType([ApiClientConstant.InstantCheckup.Type.HAIR_FRONT], instantCheckups);
        if (hairTopIds.length !== 2 && hairFrontIds.length !== 2) {
          this.broadcaster.broadcast('NOTIFY', { message: 'Can\'t find two hair Photo',
            type: this.appConfig.Shared.Toast.Type.ERROR });
          return;
        }
        if (hairFrontIds.length === 2) {
          this.window.nativeWindow.open(`${url}/compare/${hairFrontIds[0]}/${hairFrontIds[1]}/${this.user.get('username')}`
            + `?ids=${hairTopIds}&followUpId=${this.followUp?.id}`);
        } else {
          this.window.nativeWindow.open(`${url}/compare/${hairTopIds[0]}/${hairTopIds[1]}/${this.user.get('username')}`
            + `?followUpId=${this.followUp?.id}`);
        }
        break;
      }
      case 'body': {
        const bodyIds = this.getTwoComparisonOfType([ApiClientConstant.InstantCheckup.Type.BODY], instantCheckups);
        if (bodyIds.length !== 2) {
          this.broadcaster.broadcast('NOTIFY', { message: 'Can\'t find two Body photo',
            type: this.appConfig.Shared.Toast.Type.ERROR });
          return;
        }
        this.window.nativeWindow.open(`${url}/compare/${bodyIds[0]}/${bodyIds[1]}/${this.user.get('username')}`
          + `?followUpId=${this.followUp?.id}`);
        break;
      }
      default:
    }
  }

  getTwoComparisonOfType(types: Array<string>, instantCheckups_: Array<any>): Array<string> {
    const instantCheckups = instantCheckups_.filter((each: any) => types.includes(each.type));
    if (!instantCheckups?.length) return [];
    const instantCheckupIds = [];
    instantCheckupIds.push(instantCheckups[0].objectId);
    // eslint-disable-next-line no-restricted-syntax
    for (const each of instantCheckups) {
      if (moment(each.createdAt).isSameOrBefore(moment().subtract(15, 'days'))) {
        instantCheckupIds.push(each.objectId);
        break;
      }
    }
    if (instantCheckupIds.length !== 2 || instantCheckupIds[0] === instantCheckupIds[1]) {
      return [];
    }
    return instantCheckupIds;
  }

  async deleteInstantCheckUp(instantCheckUp: any): Promise<void> {
    if (!instantCheckUp) {
      this.broadcaster.broadcast('NOTIFY', { message: 'instantCheckUp not found',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    await instantCheckUp.destroy();
    this.userData.forEach((each: any) => {
      each[0].instantCheckups?.forEach((instantCheckup: any, index: number) => {
        if (instantCheckUp.id === instantCheckup.instantCheckup?.id) {
          each[0].instantCheckups.splice(index, 1);
        }
      });
    });
  }

  openRegimenEdit(regimen: any): void {
    const url = this.conn.getBaseUrl();
    this.window.nativeWindow.open(`${url}/products/regimen/${regimen.get('regimenId')}/edit`);
  }

  expand(elem: any):void {
    elem.target.scrollIntoView();
  }

  open(url: string): void {
    if (this.isInternalUser) return;
    this.router.navigate([url]);
  }

  openInstantCheckup(item: any): void {
    const url = this.conn.getBaseUrl();
    this.window.nativeWindow.open(`${url}/user/instantCheckup/${item.instantCheckup.id}?username=${this.user.get('username')}`);
  }

  openInstantCheckupEditPopup(id: string, imageUrl: string): void {
    const dialogRef = this.dialog.open(EditInstantCheckup, { data: { id, imageUrl } });
    dialogRef.afterClosed().subscribe((data: any): any => {
      this.userData.forEach((each: any, index: any) => {
        each[0].instantCheckups?.forEach((instantCheckup: any) => {
          if (data.id === instantCheckup.instantCheckup?.id) {
            instantCheckup.instantCheckup.set('type', data.get('type'));
          }
        });
      });
    });
  }

  openReport(item: any): void {
    this.router.navigate([`/user/follow-up-report/${item.progressReport.id}`]);
  }

  clearInstantCheckupsCompareIds(): void {
    this.instantCheckupsCompareIds = [];
    this.selectedInstantCheckup = [];
  }

  async loadFollowUp(): Promise<void> {
    const requestPayload: RequestQueryPayload<Table.FollowUp> = {
      where: {
        user: this.user,
        State: [
          ApiClientConstant.FollowUp.State.PENDING,
          ApiClientConstant.FollowUp.State.WAITING_FOR_IMAGE,
        ],
      },
      include: ['allocatedDoctor', 'regimens'],
      descending: 'followUpCount',
      limit: 1,
    };
    [this.followUp] = await this.conn.findFollowUps(requestPayload);
  }

  openSupportTicketHistory(): void {
    if (!this.attendToChatCacheObj) {
      return;
    }
    this.dialog.open(SupportTicketHistoryComponent, {
      panelClass: 'w-half',
      data: { user: this.attendToChatCacheObj.get('user') },
    });
  }

  openFollowUpModal(SelectedPopUpType: string): void {
    const dialogRef = this.dialog.open(FollowUpDialog, {
      data: {
        SelectedPopUpType,
        followUp: this.followUp,
        userId: this.user.get('username'),
        chatUser: this.user,
        complementProductIds: this.complementProductIds,
      },
    });
    dialogRef.afterClosed().subscribe(async () => {
      const followUp = await this.conn.findOneFollowUp({ where: { objectId: this.followUp.id }, project: ['State'] });
      if (followUp.get('State') === this.appConfig.Shared.FollowUp.State.FINISHED) {
        this.broadcaster.broadcast('NOTIFY', { message: 'FollowUp Finished Successfully',
          type: this.appConfig.Shared.Toast.Type.SUCCESS });
        this.followUp.set('State', this.appConfig.Shared.FollowUp.State.FINISHED);
      } else {
        this.broadcaster.broadcast('NOTIFY', { message: 'Error in Saving Followup',
          type: this.appConfig.Shared.Toast.Type.ERROR });
        this.window.nativeWindow.location.reload();
      }
    });
  }

  async findFollowUpChatURL(): Promise<void> {
    this.followUps = await this.conn.findFollowUps({ where: { user: this.user },
      include: ['regimens', 'allocatedDoctor'],
      descending: 'followUpCount',
      project: ['followUpCount', 'State', 'allocatedDoctor.username' as 'allocatedDoctor'] });
    if (!this.followUps?.length) {
      return;
    }
    this.selectedFollowupId = this.followUps[0].id;
    this.webAppUrl = this.dom.bypassSecurityTrustResourceUrl(
      `${this.conn.getWebAppUrl()}/chatV2/${this.selectedFollowupId}?username=${this.user.get('username')}&type=followUp&loginType=token`);
  }

  updateIframe(selectedFollowUpId: string = this.selectedFollowupId): void {
    this.webAppUrl = this.dom.bypassSecurityTrustResourceUrl(
      `${this.conn.getWebAppUrl()}/chatV2/${selectedFollowUpId}?username=${this.user.get('username')}&type=followUp&loginType=token`);
  }

  callUserThroughExotel(number: any): void {
    this.conn.callThroughExotel(number, 'FollowUpPage')
      .then(() => this.broadcaster.broadcast('NOTIFY', { message: 'Connecting',
        type: this.appConfig.Shared.Toast.Type.SUCCESS }))
      .catch((error: any) => this.broadcaster.broadcast('NOTIFY', { message: error.message,
        type: this.appConfig.Shared.Toast.Type.ERROR }));
  }

  async updateDefaultDataForNewUI(): Promise<void> {
    if (!this.showNewUI) return;
    this.defaultSelectedFollowUp = `checkup #${this.followUp.get('followUpCount')}`
      + ` dr. ${this.followUp.get('allocatedDoctor')?.get('username').split('_')[0]}`;

    this.completedFollowUpOptions = [];
    this.followUps.forEach((each: any) => {
      this.completedFollowUpOptions.push({
        display: `checkup #${each.get('followUpCount')} dr. ${each.get('allocatedDoctor')?.get('username').split('_')[0]}`,
        value: each.id,
      });
    });

    // fetch all reports sent for this followup
    const currentFollowUpReports = await this.conn.findReports({
      where: {
        user: this.user,
        followUp: this.followUp,
        isSecondaryComparison: false,
      },
      project: ['type'],
    });
    const completedReportsSet = new Set();
    currentFollowUpReports.forEach((each: any) => completedReportsSet.add(each.get('type')));
    const completedReports = Array.from(completedReportsSet);

    this.pendingReportTypes = this.followUp.get('regimens').map((regimen: any) => regimen.get('class'));
    this.pendingReportTypes = this.pendingReportTypes.filter((each: string) => !completedReports.includes(each));

    if (!this.pendingReportTypes?.length && !this.followUp.get('regimens')?.length) {
      const userInactiveRegimen = await this.conn.findOneRegimen({
        where: { forUser: this.user, active: false },
        project: ['regimenId', 'active', 'class'],
      });
      if (userInactiveRegimen) {
        this.pendingReportTypes.push(userInactiveRegimen.get('class'));
        this.selectedRegimen = userInactiveRegimen;
        this.selectedFollowupClass = userInactiveRegimen.get('class');
      }
    } else {
      if (this.pendingReportTypes.includes(ApiClientConstant.Regimen.Class.FACE)) {
        this.selectedFollowupClass = ApiClientConstant.Regimen.Class.FACE;
      } else {
        this.selectedFollowupClass = this.pendingReportTypes[0];
      }
      this.selectedRegimen = this.regimens.find((regimen: any) => regimen.get('class') === this.selectedFollowupClass);
    }

    await Promise.all([
      this.checkForRegimenProductChange(),
      this.checkIfUnderNPDExperiment(),
      this.checkRegimenHasDiscontinuedProducts(),
    ]);
  }

  async onChangeSelectedFollowUpClass(event: ValueOf<typeof ApiClientConstant.FollowUpReport.Type>): Promise<void> {
    this.selectedFollowupClass = event;
    this.selectedRegimen = this.regimens.find((regimen: any) => regimen.get('class') === this.selectedFollowupClass);
    this.VoiceRecordingComponent.resetAudio();
    delete this.audioUrl;
    this.RegimenProductsEditComponent.regimen = this.selectedRegimen;
    await this.RegimenProductsEditComponent.updateRegimenDetails();
    await this.checkIfUnderNPDExperiment();
    await this.checkForRegimenProductChange();
    await this.checkRegimenHasDiscontinuedProducts();
  }

  changeSelectedChatType(selectChat: boolean): void {
    if (selectChat) {
      this.selectedChatType = 'userChat';
      return;
    }
    this.selectedChatType = 'chatSummary';
  }

  needHelpButtonClicked(): void {
    const allActiveRegimenClass = this.followUp.get('regimens').map((regimen: any) => regimen.get('class'));
    if (allActiveRegimenClass?.length > this.pendingReportTypes?.length) {
      this.openFollowUpModal(this.appConfig.FollowUp.PopupType.MAKE_NEW_FOLLOWUP);
      return;
    }
    this.openFollowUpModal(this.appConfig.FollowUp.PopupType.WAITING_FOR_IMAGE);
  }

  onUpdateFollowUpOptions(selectedFollowUpId: string): void {
    this.updateIframe(selectedFollowUpId);
    this.selectedFollowupId = selectedFollowUpId;
  }

  private async checkIfUnderNPDExperiment(): Promise<void> {
    const attendToChatCache = JSON.parse(JSON.stringify(await this.conn.getAttendToChatUser({
      where: {
        user: this.user,
      },
      project: ['userLabel'],
    })));
    if (!attendToChatCache?.length) return;
    this.extraData.npdExperimentEnabled = !!attendToChatCache.find((each: any) => each.userLabel?.includes('Npd Experiment'));
  }

  private async checkForRegimenProductChange(): Promise<void> {
    if (!this.selectedRegimen) return;
    const durationToEditRegimenInWeeks = 12;
    if (moment().subtract(durationToEditRegimenInWeeks, 'week').isSameOrBefore(this.selectedRegimen.get('createdAt'))) {
      return;
    }
    const numberOfProductsAdded = await ApiConnector.count(Table.ActionLog, {
      where: {
        type: ApiClientConstant.ActionLog.Type.REGIMEN_CHANGE,
        regimen: this.selectedRegimen,
        actionQuestionUniqueId: 'productAdded',
        createdAt: { $gte: moment().subtract(durationToEditRegimenInWeeks, 'week').toDate() },
      },
    });
    if (!numberOfProductsAdded) {
      this.extraData.regimenChangeNeeded = true;
    }
  }

  private async checkRegimenHasDiscontinuedProducts(): Promise<void> {
    const regimenProducts = this.selectedRegimen.get('products')?.map((product: any) => product.id);
    const discontinuedProducts = await ApiConnector.find(Table.Catalog, {
      where: { objectId: regimenProducts, inventoryStatus: ApiClientConstant.Catalog.InventoryStatus.DISCONTINUED },
      project: ['title'],
    });
    const recentDeliveredOrTransitOrders = await ApiConnector.find(Table.Order, {
      where: {
        user: this.selectedRegimen.get('forUser'),
        products: discontinuedProducts,
        stage: { $in: ApiClientConstant.Order.OrderStagePostOrderPlaced },
        actualCreatedAt: { $exists: true },
        lastStageChangeAt: {
          $gte: moment().startOf('day').subtract(15, 'days').toDate(),
        },
      },
      project: ['products'],
    });
    const recentDeliveredOrTransitProductIds = recentDeliveredOrTransitOrders
      .flatMap((order: Table.Order) => order.get('products'))
      .map((product: Table.Catalog) => product.id);
    const filteredDiscontinuedProducts = discontinuedProducts
      .filter((product: Table.Catalog) => !recentDeliveredOrTransitProductIds.includes(product.id));
    this.extraData.discontinuedProducts = filteredDiscontinuedProducts.map((product: Table.Catalog) => product.get('title').trim());
  }

  createReport(reportObj: any): void {
    this.reportObject = reportObj;
    if (!this.reportObject.treatmentProgress?.length
      || !this.reportObject.treatmentOutcome?.selectedTreatmentOutcome
      || !Object.keys(this.reportObject.imageConfigs[0] || {}).length) {
      this.disableFinishAndSendButton = true;
    } else {
      this.disableFinishAndSendButton = false;
    }
    if (!this.pendingReportTypes?.length) {
      this.disableFinishAndSendButton = false;
    }
  }

  async finishAndSendReport(): Promise<any> {
    if (this.disableFinishAndSendButton) {
      return;
    }
    this.disableFinishAndSendButton = true;
    const checkDoctorCanCompleteFollowup = await this.checkDoctorCanCompleteFollowup();
    if (!checkDoctorCanCompleteFollowup) {
      this.disableFinishAndSendButton = false;
      return;
    }
    if (!this.pendingReportTypes?.length) {
      this.openFollowUpModal(this.appConfig.FollowUp.PopupType.MAKE_NEW_FOLLOWUP);
      this.disableFinishAndSendButton = false;
      return;
    }
    const audioExists = this.VoiceRecordingComponent.isRecordingAvailable();
    if (!audioExists) {
      this.broadcaster.broadcast('NOTIFY', { message: 'Audio Missing', type: this.appConfig.Shared.Toast.Type.ERROR });
      this.disableFinishAndSendButton = false;
      return;
    }
    if (!this.audioUrl) {
      this.audioUrl = await this.VoiceRecordingComponent.saveRecording();
    }
    if (!this.audioUrl) {
      this.broadcaster.broadcast('NOTIFY', { message: 'Audio URL Missing', type: this.appConfig.Shared.Toast.Type.ERROR });
      this.disableFinishAndSendButton = false;
      return;
    }
    if (!this.reportObject.imageConfigs[0].imageConfig) {
      this.broadcaster.broadcast('NOTIFY', { message: 'imageConfig missing', type: this.appConfig.Shared.Toast.Type.ERROR });
      this.disableFinishAndSendButton = false;
      return;
    }
    if (!this.reportObject.treatmentProgress?.length) {
      this.broadcaster.broadcast('NOTIFY', { message: 'Treatment Progress missing', type: this.appConfig.Shared.Toast.Type.ERROR });
      this.disableFinishAndSendButton = false;
      return;
    }
    if (!this.reportObject.imageConfigs[0].afterInstantId || !this.reportObject.imageConfigs[0].beforeInstantId) {
      this.broadcaster.broadcast('NOTIFY', { message: 'InstantCheckups missing', type: this.appConfig.Shared.Toast.Type.ERROR });
      this.disableFinishAndSendButton = false;
      return;
    }
    if (!this.reportObject.treatmentOutcome?.selectedTreatmentOutcome) {
      this.broadcaster.broadcast('NOTIFY', { message: 'Treatment outcome missing', type: this.appConfig.Shared.Toast.Type.ERROR });
      this.disableFinishAndSendButton = false;
      return;
    }
    try {
      await this.RegimenProductsEditComponent.saveRegimen();
      if (this.isIsotretConsent) {
        await this.consentForm.triggerIsotretConsentForm();
      }
      if (this.isFinasterideConsent) {
        await this.consentForm.triggerFinasterideConsentForm();
      }
      this.broadcaster.broadcast('NOTIFY', { message: 'Regimen saved', type: this.appConfig.Shared.Toast.Type.SUCCESS });
    } catch (error) {
      if (error.message === 'Not more than two months antibiotics allowed in regimen'
        || error.message.acknowledgementField === 'AddTwoMonthAntibioticReason') {
        const dialogRef = this.dialog.open(DataUploadModal, { data: { title: 'Reason for continuing antibiotics' } });
        dialogRef.afterClosed().subscribe(async (result: any) => {
          if (!result) {
            this.broadcaster.broadcast('NOTIFY', { message: 'Reason not added', type: this.appConfig.Shared.Toast.Type.ERROR });
            this.disableFinishAndSendButton = false;
            return;
          }
          this.RegimenProductsEditComponent.regimen.set('continueAntibioticsReason', result);
          this.disableFinishAndSendButton = false;
          await this.finishAndSendReport();
        });
      } else if (error.message?.includes('Products you are trying to remove are recently delivered or moved to transit')) {
        this.broadcaster.broadcast('NOTIFY', {
          message: 'Products you are trying to remove are recently delivered or moved to transit',
          type: this.appConfig.Shared.Toast.Type.ERROR,
        });
        this.RegimenProductsEditComponent.morningProducts = this.helper.deepCopy(this.RegimenProductsEditComponent.originalMorningProducts);
        this.RegimenProductsEditComponent.nightProducts = this.helper.deepCopy(this.RegimenProductsEditComponent.originalnightProducts);
      } else {
        this.broadcaster.broadcast('NOTIFY', { message: error.message, type: this.appConfig.Shared.Toast.Type.ERROR });
        this.disableFinishAndSendButton = false;
      }
    }
  }

  async updateSecondaryComparisons(primaryReport: any): Promise<any[]> {
    const secondaryComparisons = this.reportObject.imageConfigs.slice(1);
    if (!Object.keys(secondaryComparisons[0] || {}).length) return [];
    const promises: Promise<any>[] = [];
    secondaryComparisons.forEach((each: { beforeInstantId: string, afterInstantId: string, imageConfig: any }) => {
      const followupReport = new Table.FollowUpReport();
      const beforeInstantCheckup = new Table.InstantCheckup();
      beforeInstantCheckup.id = each.beforeInstantId;
      followupReport.set('beforeInstantCheckup', beforeInstantCheckup);
      const afterInstantCheckup = new Table.InstantCheckup();
      afterInstantCheckup.id = each.afterInstantId;
      followupReport.set('afterInstantCheckup', afterInstantCheckup);
      followupReport.set('imageConfig', each.imageConfig);
      followupReport.set('type', this.selectedFollowupClass);
      followupReport.set('doctor', primaryReport.get('doctor'));
      followupReport.set('user', primaryReport.get('user'));
      followupReport.set('isSecondaryComparison', true);
      followupReport.set('isImageOnlyReport', true);
      if (this.followUp) {
        followupReport.set('followUp', this.followUp);
      }
      promises.push(followupReport.save());
    });
    return Promise.all(promises);
  }

  wantsToTriggerConsentForm(isotretConsent: boolean):void {
    if (isotretConsent) {
      this.isIsotretConsent = true;
    } else {
      this.isFinasterideConsent = true;
    }
  }

  wantsToCancelSendingConsentForm(): void {
    this.isFinasterideConsent = false;
    this.isIsotretConsent = false;
  }

  async afterSaveRegimen(regimen: any): Promise<void> {
    const report = new Table.FollowUpReport();
    report.set('treatmentProgress', this.reportObject.treatmentProgress);
    report.set('user', this.user);
    report.set('followUp', this.followUp);
    report.set('isImageOnlyReport', false);
    report.set('investigations', this.reportObject.investigations);
    report.set('isSecondaryComparison', false);
    report.set('audioURL', this.audioUrl);
    report.set('type', this.selectedFollowupClass);
    report.set('treatmentOutcome', { selectedTreatmentOutcome: this.reportObject.treatmentOutcome });
    report.set('imageConfig', this.reportObject.imageConfigs[0].imageConfig);
    const afterInstantCheckup = new Table.InstantCheckup();
    afterInstantCheckup.id = this.reportObject.imageConfigs[0].afterInstantId;
    report.set('afterInstantCheckup', afterInstantCheckup);
    const beforeInstantCheckup = new Table.InstantCheckup();
    beforeInstantCheckup.id = this.reportObject.imageConfigs[0].beforeInstantId;
    report.set('beforeInstantCheckup', beforeInstantCheckup);
    report.set('doctor', this.currentUser.get('type') === ApiClientConstant.User.Type.DOCTOR
      ? this.currentUser
      : this.user.get('allocatedDoctor'));
    if (this.changeNeededReason) {
      report.set('noRegimenChangeReason', this.changeNeededReason);
    }
    let secondaryComparisons = [];
    try {
      secondaryComparisons = await this.updateSecondaryComparisons(report);
    } catch (error) {
      this.broadcaster.broadcast('NOTIFY', { message: error.message, type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    report.set('secondaryComparisons', secondaryComparisons);
    try {
      await report.save();
      this.broadcaster.broadcast('NOTIFY', { message: `${this.selectedFollowupClass} Report Sent`,
        type: this.appConfig.Shared.Toast.Type.SUCCESS });
    } catch (error) {
      if (error.message === 'No new product added in last 12 weeks.') {
        const dialogRef = this.dialog.open(DataUploadModal,
          { data: {
            title: 'Reason for not changing the regimen',
            minCharLength: 10,
          } });
        dialogRef.afterClosed().subscribe(async (result: any) => {
          if (!result) {
            this.broadcaster.broadcast('NOTIFY',
              {
                message: 'Please provide a reason for not changing the regimen',
                type: this.appConfig.Shared.Toast.Type.ERROR,
              });
            return;
          }
          this.changeNeededReason = result;
          await this.finishAndSendReport();
        });
      }
      this.broadcaster.broadcast('NOTIFY', { message: error.message, type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    const completedReportIndex = this.pendingReportTypes.findIndex((each: string) => each === this.selectedFollowupClass);
    this.pendingReportTypes.splice(completedReportIndex, 1);
    if (!this.pendingReportTypes.length) {
      this.openFollowUpModal(this.appConfig.FollowUp.PopupType.MAKE_NEW_FOLLOWUP);
      this.VoiceRecordingComponent.resetAudio();
      delete this.audioUrl;
      this.disableFinishAndSendButton = true;
      return;
    }
    await this.onChangeSelectedFollowUpClass(this.pendingReportTypes[0]);
  }

  changeRegimen(event: Array<any>): void {
    this.getComplementProductsFromRegimenChanges(event);
    this.RegimenProductsEditComponent.changeRegimenOnBasisOfTreatmentOutcome(event);
  }

  getComplementProductsFromRegimenChanges(changes: Array<any>): void {
    this.complementProductIds = changes.filter((change: any) => change.isComplementory)
      .map((change: any) => change.productId);
  }

  disableRegimenEdit(disableRegimenEdit: boolean): void {
    if (disableRegimenEdit) {
      this.customVoiceNoteToggle = true;
      this.RegimenProductsEditComponent.disableEdit = true;
      this.RegimenProductsEditComponent.showRegimenEditButton = false;
    } else {
      this.customVoiceNoteToggle = false;
      this.RegimenProductsEditComponent.disableEdit = false;
      this.RegimenProductsEditComponent.showRegimenEditButton = true;
    }
  }

  async changeVoiceNote(audioTemplateName: string): Promise<void> {
    this.audioTemplateNameCopy = audioTemplateName;
    const audioTemplate = await this.conn.findAudioTemplateByName(audioTemplateName, this.userCommunicationLanguage);
    if (!audioTemplate) {
      this.broadcaster.broadcast('NOTIFY', { message: 'Voice Note not found for the followupSop',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      this.VoiceRecordingComponent.setAudioSourceURL('');
      this.VoiceRecordingComponent.reset();
      return;
    }
    const userLanguage = audioTemplate.get('urlLanguageString').get(`${this.userCommunicationLanguage}SignedUrl`)
      || audioTemplate.get('urlLanguageString').get(`${this.appConfig.Shared.Languages.EN}SignedUrl`);
    this.VoiceRecordingComponent.setAudioSourceURL(userLanguage);
  }

  async checkDoctorCanCompleteFollowup(): Promise<boolean> {
    const currentFollowupStatus = await this.conn.findOneFollowUp({ where: { objectId: this.followUp.id },
      project: ['allocatedDoctor', 'State'] });
    if (currentFollowupStatus.get('State') !== this.appConfig.Shared.FollowUp.State.PENDING) {
      this.broadcaster.broadcast('NOTIFY', {
        message: 'followup is not in pending Stage',
        type: this.appConfig.Shared.Toast.Type.ERROR,
      });
      return false;
    }
    if (currentFollowupStatus.get('allocatedDoctor')?.id !== this.currentUser.id) {
      alert('followup allocated to a different doctor');
      return false;
    }
    return true;
  }

  async toggleVoiceNote(): Promise<void> {
    this.disableVoiceNoteSOP = !this.disableVoiceNoteSOP;
    if (this.disableVoiceNoteSOP) {
      this.VoiceRecordingComponent.setAudioSourceURL('');
      this.VoiceRecordingComponent.reset();
      const dialogRef = this.dialog.open(DataUploadModal,
        { data: {
          title: 'Reason for disabling FollowUpSOP Voice Note',
          minCharLength: 10,
        } });
      dialogRef.afterClosed().subscribe(async (result: any) => {
        if (!result) {
          this.disableVoiceNoteSOP = !this.disableVoiceNoteSOP;
          await this.changeVoiceNote(this.audioTemplateNameCopy);
          return;
        }
        if (result) {
          const details = {
            reason: result,
            doctor: this.conn.getCurrentUser().get('username'),
            selectedSop: [...this.selectedRegimen.get('followUpSopTransition')].pop(),
          };
          const voiceEditReasons: Array<Object> = await this.selectedRegimen?.get('followUpSopVoiceEditReason') || [];
          voiceEditReasons.push(details);
          this.RegimenProductsEditComponent.regimen.set('followUpSopVoiceEditReason', voiceEditReasons);
        }
      });
      return;
    }
    await this.changeVoiceNote(this.audioTemplateNameCopy);
  }

  onChooseSection(event: string): void {
    const elem = this.window.nativeWindow.document.getElementById(event);
    elem?.scrollIntoView({ behavior: 'smooth' });
  }
  onNewMedicalNoteAdded(event: boolean): void {
    this.newMedicalNoteAdded = true;
  }
}
